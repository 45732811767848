import { useState, useEffect } from "react";
import './App.css';

import { Button, Text } from "evergreen-ui";

import Auth from "./Auth/Auth";

import Header from "./LoggedIn/Header";
import Billing from "./LoggedIn/Billing";

import axios from "axios";

import { remainingDaysBetweenDates, addDaysToTimestamp } from "./utils";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState(false);
  const [payment, setPayment] = useState(false);
  const [li, setLi] = useState(false);
  const [sub, setSub] = useState('');
  const [plan, setPlan] = useState('');
  const [userId, setUserId] = useState('');
  const [trail, setTrail] = useState(false);
  const [days, setDays] = useState("");

  const loginWithGoogle = async () => {

    // Replace with your Auth0 client ID and redirect URI
    const client_id = 'W9fH8jkfUIAttUFBVOMWMaVHHNYCRdml';
    const redirectUri = "http://app.socialinbox.io";

    // Construct the Auth0 authorization URL
    const auth0Url = `https://dev-wkd8jg1jy8t616go.us.auth0.com/authorize?client_id=${client_id}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=token&scope=offline_access openid profile email`;

    try {
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
      // Open a new window or tab to initiate the web authentication flow
      let windowPopup = window.open(auth0Url, "auth", params);

    } catch (e) {
      console.log(e);
    }
  }

  const googleAuth = async (access_token) => {

    if (access_token) {
      const tokenWithBearer = 'Bearer ' + access_token;

      // Get user details from Auth0
      const userInfoResponse = await axios.get('https://dev-wkd8jg1jy8t616go.us.auth0.com/userinfo', {
        headers: {
          Authorization: tokenWithBearer,
        },
      });

      const { email } = userInfoResponse.data;
      const password = '123456789';

      try {

        // Send the user details to your server for registration
        const registrationResponse = await axios.post('https://api.socialinbox.io/auth/signup', {
          email,
          password,
          google: userInfoResponse.data,
        });

        await axios.post("https://api.socialinbox.io/trail", {
          userId: registrationResponse.data.userId,
          urn: "",
          trail: Date.now(),
        })
        // Handle the registration success
        localStorage.setItem("token", registrationResponse.data.jwt);
        localStorage.setItem("currentUser", registrationResponse.data.userId);
        localStorage.setItem("payment", "false");
        localStorage.setItem("li", "false");
        localStorage.setItem("picture", userInfoResponse.data.picture);
        // Send the user information back to the main window
        window.opener.postMessage({ type: 'userInfo', info: JSON.stringify(userInfoResponse) }, '*');
        window.close();
      } catch (e) {
        if (e.response.data.code === 1) {
          // Update user details if the email already exists
          await axios.put('https://api.socialinbox.io/user/updateUser', {
            email,
            google: userInfoResponse.data,
          });
          // Send the user information back to the main window
          window.opener.postMessage({ type: 'userInfo', info: JSON.stringify(userInfoResponse) }, '*');
          window.close();
        }
      }

    }
  }

  window.addEventListener('message', async (event) => {
    if (event.data && event.data.type === 'userInfo') {
      const userInfo = JSON.parse(event.data.info);
      // Now you can access the user information in your main window
      localStorage.setItem("payment", "false");
      localStorage.setItem("li", "false");
      localStorage.setItem("picture", userInfo.data.picture);
      await userLogin(userInfo.data.email);
    } else if (event.data && event.data.type === 'error') {
      console.log('error');
    }
  });

  const userLogin = async (email) => {
    const { data } = await axios.post(
      `https://api.socialinbox.io/auth/login`,
      {
        email: email,
        password: "123456789"
      }
    );
    localStorage.setItem("token", data.jwt);
    localStorage.setItem("currentUser", data.userId);
    localStorage.setItem("payment", data.payment);
    localStorage.setItem("li", data.linkedIn);
    localStorage.setItem("picture", data.picture);

    setLoggedIn(true);
  }

  useEffect(() => {
    const user = localStorage.getItem("currentUser");
    setUserId(user);
    if (user) {
      axios.post("https://api.socialinbox.io/trail/get", {
        userId: user,
        urn: ""
      }).then((response) => {
        const addDays = addDaysToTimestamp(response.data.trail, 101);
        const remainingDays = remainingDaysBetweenDates(Date.now(), addDays);
        if (remainingDays > 0) {
          setTrail(true);
        }
        setDays(remainingDays);
      });
    }
    const token = localStorage.getItem("token");
    const payment = localStorage.getItem("payment");
    const li = localStorage.getItem("li");
    setPayment(payment);
    setLi(li);

    if (user && token) {
      setLoggedIn(true);

      let check = true;

      // If payment exists get payment data
      axios.get(`https://api.socialinbox.io/payment/id?id=${user}`).then((response) => {
        check = false;
        console.log("RESPONSE", response);
        const data = response.data.data;
        // setPlan(data.plan_id);
        // setSub(data.sub_id);
      });

      if (check) {
        // If payment not exist
        let search = window.location.search;
        const urlParams = new URLSearchParams(search);
        const sub_id = urlParams.get('sub_id');
        const plan_id = urlParams.get('plan_id');
        if (sub_id && plan_id) {
          setSub(sub_id);
          setPlan(plan_id);
          axios.post("https://api.socialinbox.io/payment/success", {
            userId: user
          })

          axios.post('https://api.socialinbox.io/payment', {
            user: { id: user },
            sub_id,
            plan_id
          });
          setPayment("true");
        }
      }
    }

    let hashFragment = window.location.hash;
    // Remove the '#' character from the hash fragment
    const hashWithoutHashSymbol = hashFragment.slice(1); // Remove the '#' symbol

    // Split the hash fragment into an array of key-value pairs
    const keyValuePairs = hashWithoutHashSymbol.split('&');

    // Create an object to store the parameters
    const params = {};

    // Loop through the key-value pairs and populate the params object
    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split('=');
      params[key] = value;
    });

    // Access specific parameters by name
    const access_token = params['access_token'];

    if (access_token !== "") {
      googleAuth(access_token);
    }
  }
  )

  return (
    <>
      {
        loggedIn ? (
          <div>
            <Header
              setSelectedTab={setSelectedTab}
              user={{ fullName: 'Vivek Anand', profilePicture: '' }}
              setUserLoggedIn={setLoggedIn}
            />
            {selectedTab === 'Billing' ? <Billing /> : null}
            <br />
            <Text size="large" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Trail Status : {days > 0 ? `${days} Days 👍` : " Trail Ended 👎"}</Text>
            <br />
            <Text size="large" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>Billing Status : {payment === 'true' ? 'That looks great! 👍' : " Not billed yet 👎"}</Text>
            <br />
            <Text size="large" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>LinkedIn Status : {li === 'true' ? 'That looks great! 👍' : " Not linked yet 👎"}</Text>
            <br />
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%"
            }}>
              <Button size="large"
                appearance="primary"
                onClick={() => { window.postMessage({ type: 'openSocialInbox', info: `chrome-extension://ehalejlfkpgndmgibdmnclnleaeaiilf/dashboard.html?sub_id=${sub}&plan_id=${plan}&user_id=${userId}&trail=${trail}` }, '*'); }}
              >Go to Extension</Button>
            </div>
          </div >) : (<Auth setUserLoggedIn={setLoggedIn} loginWithGoogle={loginWithGoogle} />)
      }
    </>
  )
}

export default App;
