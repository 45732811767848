import React from "react";
import { Pane, Dialog } from "evergreen-ui";

function Modal({ setShowModal, showModal, type, plan }) {

    return (
        <Pane>
            <Dialog
                isShown={showModal}
                onCloseComplete={() => setShowModal(false)}
                hasFooter={false}
                preventBodyScrolling
            >
                <iframe seamless="seamless" scrolling="no" style={{ width: '100%', height: '490px', border: 'none' }} title={type} src={plan}></iframe>
            </Dialog>
        </Pane>
    )
}

export default Modal