import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Pane,
    Heading,
    Text,
    Tooltip,
    Popover,
    Menu,
    LogOutIcon,
    DollarIcon,
    RefreshIcon,
    NotificationsIcon,
    Position,
    toaster,
    Tab,
} from 'evergreen-ui';
import Cookies from 'js-cookie';
import './Header.css';
import logo from '../logo.svg';
// import axios from 'axios';

function Header({
    setSelectedTab,
    setUserLoggedIn
}) {
    //const [user, setUser] = useState();

    const picture = localStorage.getItem("picture");

    // Get the value of a cookie by name
    // function getCookie(name) {
    //     const cookieString = document.cookie;
    //     console.log(cookieString);
    //     const cookies = cookieString.split(';');
    //     for (let i = 0; i < cookies.length; i++) {
    //         const cookie = cookies[i].trim();
    //         // Check if this cookie has the desired name
    //         if (cookie.startsWith(name + '=')) {
    //             // Extract and return the cookie value
    //             return cookie.substring(name.length + 1);
    //         }
    //     }
    //     // Return null if the cookie is not found
    //     return null;
    // }

    // Usage
    //const JSESSIONID = getCookie('MMCSRF');

    /**
 * @desc Get the info of the logged in user
 * @param {string} url - fetch link
 * @param {boolean} withAcceptHeader - To use accept header or not
 * @param {string} [method = 'GET'] - Method of the fetch request
 * @param {object} [body = null] - body of the fetch request
 * @return {promise} => user is logged in ? userInfo : undefined
 */

    async function fetchLinkedInUrl(
        url,
        withAcceptHeader = false,
        method = 'GET',
        body = null,
        params
    ) {
        try {
            const csrfToken = await Cookies.get({ domain: "https://linkedin.com" });
            console.log(csrfToken)
            if (!csrfToken) {
                return false;
            }

            const headers = withAcceptHeader
                ? {
                    'x-restli-protocol-version': '2.0.0',
                    'csrf-token': csrfToken,
                    'x-li-track':
                        '{"clientVersion":"1.5.*","osName":"web","timezoneOffset":1,"deviceFormFactor":"DESKTOP","mpName":"voyager-web"}',
                }
                : {
                    accept: 'application/vnd.linkedin.normalized+json+2.1',
                    'x-restli-protocol-version': '2.0.0',
                    'csrf-token': csrfToken,
                    'x-li-track':
                        '{"clientVersion":"1.5.*","osName":"web","timezoneOffset":1,"deviceFormFactor":"DESKTOP","mpName":"voyager-web"}',
                };

            if (params) {
                let paramStr = '';
                Object.keys(params).forEach(
                    (cur) => (paramStr += `${cur}=${params[cur]}&`)
                );

                url += `?${paramStr}`;
            }

            // console.log(url);

            const res = await fetch(url, {
                method: method,
                headers: headers,
                body,
                credentials: 'include',
            });

            // console.log(res);
            const text = await res.text();
            // console.log(text);
            const data = JSON.parse(text);
            // console.log(data);
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    // -----------------

    /**
     * @desc Get the info of the logged in user
     * @param {null}
     * @return {promise} => user is logged in ? userInfo : undefined
     */

    async function getLoggedInUser() {
        try {
            const resp = await fetchLinkedInUrl(
                'https://www.linkedin.com/voyager/api/identity/profiles/me',
                true
            );

            if (!resp) {
                // if (sendResponse) {
                //     sendNotLoggedInResponse(sendResponse);
                // }
                return false;
            }

            const result = {
                firstName: resp.firstName,
                lastName: resp.lastName,
                country: resp.geoCountryName,
                headline: resp.headline,
                summary: resp.summary,
                entityUrn: resp.entityUrn,
                industryName: resp.industryName,
                profileId:
                    resp.entityUrn &&
                    resp.entityUrn
                        .replace('urn:li:fsd_profile:', '')
                        .replace('urn:li:fs_profile:', ''),
                location: resp.geoLocationName,
                publicIdentifier: resp.miniProfile && resp.miniProfile.publicIdentifier,
                profilePicture:
                    resp.miniProfile &&
                    resp.miniProfile.picture &&
                    resp.miniProfile.picture['com.linkedin.common.VectorImage'] &&
                    resp.miniProfile.picture['com.linkedin.common.VectorImage'].rootUrl +
                    resp.miniProfile.picture['com.linkedin.common.VectorImage']
                        .artifacts[2].fileIdentifyingUrlPathSegment,
            };

            console.log(result);

            //setUser(result);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        //getLoggedInUser();
    }, [])

    return (
        <header>
            <Pane
                display="flex"
                padding={10}
                paddingX={30}
                background="#fff"
                boxShadow="0px 4px 20px rgba(0,0,0,0.06)"
            >
                <Pane flex={1} alignItems="center" display="flex">
                    <img style={{ width: 100, marginRight: 20 }} src={logo} alt="logo" />
                    <Heading is="h3" marginRight={50} fontWeight="bold">
                        Social Inbox
                    </Heading>
                    {/* {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            id={tab}
                            onSelect={() => setSelectedTab(tab)}
                            isSelected={selectedTab === tab}
                        >
                            {tab}
                        </Tab>
                    ))} */}
                </Pane>
                <Pane
                    display="grid"
                    gridTemplateColumns="1fr auto"
                    gridGap={50}
                    alignItems="center"
                >
                    {/* <Pane
                        display="grid"
                        gridTemplateColumns="auto auto auto auto"
                        gridGap={30}
                        alignItems="center"
                    >
                        <Pane>
                            <Tooltip content="Sync">
                                <RefreshIcon style={{ color: 'blue', cursor: "pointer", marginRight: '20px' }} onClick={() => sync(user)} />
                            </Tooltip>
                            <Tooltip content="Notification">
                                <NotificationsIcon style={{ color: 'blue', cursor: "pointer", marginRight: '20px' }} />
                            </Tooltip>
                        </Pane>
                        <Pane>
                            <Text whiteSpace="nowrap" size={300}>
                                Last Sync
                            </Text>
                            <br />
                            <Tooltip content="Last synced">
                                <Text whiteSpace="nowrap" size={300} fontWeight="bold">
                                    {formatTimeStamp(new Date(lastSync)).date +
                                        ' ' +
                                        formatTimeStamp(new Date(lastSync)).time}
                                </Text>
                            </Tooltip>
                        </Pane>
                        <Pane>
                            <Text whiteSpace="nowrap" size={300}>
                                Collected connections
                            </Text>
                            <br />
                            <Text whiteSpace="nowrap" size={300} fontWeight="bold">
                                <Tooltip content="Collected connections">
                                    <span style={{ color: '#5153ff' }}>
                                        {user.collectedConnections}
                                    </span>
                                </Tooltip>{' '}
                                /{' '}
                                <Tooltip content="Total connections">
                                    <span> {user.totalConnections} </span>
                                </Tooltip>
                            </Text>
                        </Pane>
                        <Pane>
                            <Text whiteSpace="nowrap" size={300}>
                                Updated connections
                            </Text>
                            <br />
                            <Text whiteSpace="nowrap" size={300} fontWeight="bold">
                                <Tooltip content="Retrieved connections">
                                    <span style={{ color: '#5153ff' }}>{retConnections}</span>
                                </Tooltip>{' '}
                                /{' '}
                                <Tooltip content="Total connections">
                                    <span> {totalConnections} </span>
                                </Tooltip>
                            </Text>
                        </Pane>
                    </Pane> */}
                    <Tooltip content={''}>
                        <Popover
                            position={Position.BOTTOM_LEFT}
                            content={({ close }) => (
                                <Menu>
                                    <Menu.Group>
                                        <Menu.Item
                                            onSelect={() => {
                                                console.log('billing');
                                                setSelectedTab('Billing');
                                            }}
                                            icon={DollarIcon}
                                        >
                                            Billing
                                        </Menu.Item>
                                        <Menu.Item
                                            onSelect={() => {
                                                console.log('logout');
                                                setSelectedTab('Logout');
                                                setUserLoggedIn(false);
                                                localStorage.removeItem("token");
                                                localStorage.removeItem("currentUser");
                                                localStorage.removeItem("payment");
                                                localStorage.removeItem("li");
                                                localStorage.removeItem("picture");
                                                // chrome.runtime.sendMessage(
                                                //     { action: 'logout' },
                                                //     (resp) => {
                                                //         if (resp.status === 'error') {
                                                //             toaster.danger('Error in logout', {
                                                //                 description: resp.error,
                                                //                 duration: 6,
                                                //             });
                                                //             return;
                                                //         }
                                                //         if (resp.status === 'success') {
                                                //             setUserLoggedIn(false);
                                                //         }
                                                //     }
                                                // );
                                            }}
                                            icon={LogOutIcon}
                                        >
                                            Logout
                                        </Menu.Item>
                                    </Menu.Group>
                                </Menu>
                            )}
                        >
                            <Avatar
                                // name={fullName}
                                size={40}
                                color="purple"
                                src={picture}
                                cursor="pointer"
                            >
                                {''}
                            </Avatar>
                        </Popover>
                    </Tooltip>
                </Pane>
            </Pane>
        </header>
    );
}
export default Header;
