import React, { useState } from "react";
import PricingCard from "../Components/PricingCard";
import BillingModal from "../Components/Modal";
import { Switch, Label } from "evergreen-ui";

export default function Billing() {
    const [checked, setChecked] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('');
    const [plan, setPlan] = useState('');

    return (
        <div>
            <div className="toggle" style={{ display: 'flex', justifyContent: 'center' }}>
                <Label style={{ color: !checked ? "blue" : "black", paddingRight: '10px' }}>Bill Monthly</Label>
                <Switch checked={checked} onChange={(e) => { console.log(e.target.checked); setChecked(e.target.checked) }} />
                <Label style={{ color: checked ? "blue" : "black", paddingLeft: '10px' }}>Bill Annually</Label>
            </div>
            <br />
            <div className="prices" style={{ display: 'flex', justifyContent: 'center' }}>
                {
                    !checked && <PricingCard
                        data={{
                            type: 'Basic Plan',
                            price: '€29/month',
                            month: true
                        }}
                        setShowModal={setShowModal}
                        setType={setType}
                        setPlan={setPlan}
                    />
                }
                {
                    checked && <PricingCard
                        data={{
                            type: 'Basic Plan',
                            price: '€299/year',
                            year: true
                        }}
                        setShowModal={setShowModal}
                        setType={setType}
                        setPlan={setPlan}
                    />
                }
                {
                    !checked && <PricingCard
                        data={{
                            type: 'Pro Plan',
                            price: '€49/month',
                            month: true
                        }}
                        setShowModal={setShowModal}
                        setType={setType}
                        setPlan={setPlan}
                    />
                }
                {
                    checked && <PricingCard
                        data={{
                            type: 'Pro with AI Plan',
                            price: '€499/year',
                            year: true
                        }}
                        setShowModal={setShowModal}
                        setType={setType}
                        setPlan={setPlan}
                    />
                }
                {
                    showModal && <BillingModal
                        setShowModal={setShowModal}
                        showModal={showModal}
                        type={type}
                        plan={plan}
                    />
                }
            </div>
        </div>
    )
}