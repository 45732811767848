import React from 'react';
import { Pane, Text, Button, RepeatIcon } from 'evergreen-ui';

const PricingCard = ({ data, setShowModal, setType, setPlan }) => {


    const selectType = () => {

        if (data.price === '€29/month') {
            setPlan("https://socialinbox.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-EUR-Monthly&utm_source=cb-app-copy");
        } else if (data.price === '€299/year') {
            setPlan("https://socialinbox.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-EUR-Yearly&utm_source=cb-app-copy");
        } else if (data.price === '€49/month') {
            setPlan("https://socialinbox.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Pro-EUR-Monthly&utm_source=cb-app-copy");
        } else if (data.price === '€499/year') {
            setPlan("https://socialinbox.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Pro-EUR-Yearly&utm_source=cb-app-copy");
        }

        setType(data.type);
        setShowModal(true);
    }

    return (
        <Pane
            width={300}
            padding={24}
            backgroundColor="white"
            borderRadius={8}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
            style={{ marginRight: '20px' }}
        >
            <Text marginBottom={16} style={{ fontSize: 'xx-large' }}>
                {data.type}
            </Text>
            <br />
            <br />
            <Text marginBottom={16} style={{ fontWeight: 'bold', fontSize: 'large' }}>
                {data.price}
            </Text>
            <br />
            <br />
            <Text size={500}><RepeatIcon /> Recurring payment per {data.month ? 'month' : 'year'}</Text>
            <br />
            <br />
            {data.month && <Button appearance="primary" marginRight={12} onClick={selectType}>Subscribe Monthly</Button>}
            {data.year && <Button appearance="default" marginRight={12} onClick={selectType}>Subscribe Annually</Button>}
        </Pane>
    );
};

export default PricingCard;
